import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DTO, VM } from '@models';
import { AuthService } from '@core/services/auth.service';
import { P5Service } from '@core/services/p5.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PlayerStateService {
    private readonly IS_LOGIN_KEY = 'isLogin';
    private readonly IS_REMEMBER_KEY = 'isRememberMe';
    private readonly IS_HEADER_NOTICE_KEY = 'isHeaderNoticeClose';

    public isSignedIn = new BehaviorSubject<boolean>(!!this.document.defaultView.localStorage.getItem(this.IS_LOGIN_KEY) || false);
    public info = new BehaviorSubject<DTO.FrontOffice.Member.GetMyInfoResponse>(null);
    public balance = new BehaviorSubject<VM.UpdateUserBalance>({ value: 0, isUpdate: false });
    public accessToken = new BehaviorSubject<string>(null);

    public constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private p5Service: P5Service, private authService: AuthService, private cookieService: CookieService) {
        this.isSignedIn.subscribe((isSignedIn) => {
            if (isSignedIn) {
                this.document.defaultView.localStorage.setItem(this.IS_LOGIN_KEY, 'true');
            } else {
                this.accessToken.next(null);
                this.document.defaultView.localStorage.removeItem(this.IS_LOGIN_KEY);
                this.cookieService.delete(this.IS_REMEMBER_KEY, '/');
                this.cookieService.delete(this.IS_HEADER_NOTICE_KEY, '/');
                this.info.next(null);
                this.balance.next({ value: 0, isUpdate: false });
            }
        });
    }

    public setPlayerInfo(playerInfo: DTO.FrontOffice.Member.GetMyInfoResponse) {
        this.info.next(playerInfo);
    }

    public signIn(loginResponse: DTO.FrontOffice.Member.LoginResponse, isRememberCookie = false) {
        // 로그인 유지 체크 > cookie 저장 expire time 유무
        if (isRememberCookie) {
            this.cookieService.set(this.IS_REMEMBER_KEY, 'true', { expires: 365, path: '/', sameSite: 'Strict' });
        }
        this.isSignedIn.next(true);
        this.setPlayerInfo({ ...loginResponse.member, verification: loginResponse.verification });
        this.accessToken.next(loginResponse.token.accessToken);
    }

    public async signOut() {
        try {
            this.isSignedIn.next(false);
            await Promise.all([this.authService.signOut(), this.p5Service.signOut()]);
        } catch (e) {
            console.log(e);
        }
    }

    public setIsHeaderNoticeCookie() {
        this.cookieService.set(this.IS_HEADER_NOTICE_KEY, 'true', { path: '/' });
    }

    public getIsHeaderNoticeCookie() {
        return this.cookieService.get(this.IS_HEADER_NOTICE_KEY);
    }

    // 토큰 재발급
    public async reissueToken() {
        try {
            const res = await this.authService.reissueToken({ isRememberMe: 'true' === this.cookieService.get(this.IS_REMEMBER_KEY) });
            if (!this.isSignedIn.getValue()) {
                this.isSignedIn.next(true);
            }
            // if (!this.document.defaultView.localStorage.getItem(this.IS_LOGIN_KEY)) {
            //     this.document.defaultView.localStorage.setItem(this.IS_LOGIN_KEY, 'true');
            // }
            this.setPlayerInfo({ ...res.member, verification: res.verification });
            this.accessToken.next(res.token.accessToken);
            return true;
        } catch (e) {
            console.error(e);
            this.signOut();
            this.router.navigateByUrl('/');
            return false;
        }
    }
}
