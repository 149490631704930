export const environment = {
    production: false,
    env: 'STAGE',
    r2Host: 'https://bw-stage.wsopplus.net/Pokerstake',
    backendHost: 'https://api-stage.pokerstake.com',
    paymentHost: 'https://pay-stage.pokerstake.com',
    stream: 'https://stream7-stage.pokerstake.com',
    imageHost: '//cdn.pokerstake.com',
    pocketFives: 'https://contents-stage.pokerstake.com',
    p5BackendHost: 'https://contents-stage.pokerstake.com/public/api',
    p7Host: '//staking7-stage.pokerstake.com',
    recaptchaSiteKey: '6Ldru40jAAAAAHamQbkYA2RYxp3UaKcxzJ0L5YYI',
    nuveiMerchantId: '3261468619376904786',
    nuveiMerchantSiteId: '234588',
    appVersion: require('../../package.json').version
};
