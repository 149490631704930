<!-- [s] 띠배너 영역 -->
<app-maintenance-notice></app-maintenance-notice>
<div *ngIf="isHeaderNotice" [@closeHeaderNotice]="isHeaderNotice" class="header-notice">
    Verification related to email change has not been completed.
    <button (click)="closeHeaderNotice()" type="button" class="btn-close"><i class="fas fa-times-circle"></i></button>
</div>
<!-- [e] 띠배너 영역 -->

<header class="header-container" [class.open-side-menu]="isShowSideMenu">
    <div class="user-menu-control">
        <button type="button" class="button-menu" (click)="toggleSideMenu(true)" aria-label="Side Menu"><i class="fas fa-bars"></i></button>
    </div>
    <section class="header-navbar">
        <h1 class="heading-title">
            <span class="logo">
                <button (click)="goMain()" aria-label="Go to Main"><img [src]="environment.imageHost + '/staking/logo/pokerstake_logo.svg'"
                                                                        alt="PokerStake Staking"></button>
            </span>
        </h1>
        <button type="button" class="button-toggle-panel" *ngIf="isShowSideMenu" [@visibleCloseMenuButton]="isShowSideMenu"
                (click)="toggleSideMenu(false)"><i class="fas fa-times"></i></button>
        <app-navbar (toggleSidePanel)="toggleSideMenu($event)" [toggleSideMenu]="isShowSideMenu" [isSignedIn]="isSignedIn" [playerInfo]="playerInfo"
                    [isInitialize]="isInitialize"></app-navbar>
    </section>
    <button *ngIf="isVisibleToggleFilterButton" type="button" class="button-toggle-filter" [class.apply]="isSelectedStakingFilter"
            (click)="onToggleFilterPanel()"></button>

    <section class="header-user" *ngIf="isSignedIn && playerInfo">
        <section class="user-balance-area">
            <div class="user-balance-inner">
                <div>
                    <span class="balance" [class.realtime-update]="balance.isUpdate"
                          (animationend)="updateBalanceAnimationEnd()">{{ balance?.value | money }}</span>
                </div>
                <button type="button" class="btn-deposit" (click)="toggleSideMenu(false)" [routerLink]="'/wallet'" aria-label="Go to Deposit">
                    Deposit
                </button>
            </div>
        </section>
        <button type="button" class="button-my-staking"
                [routerLink]="['/my-staking', isSeller ? 'sold' :'bought']"
                [queryParams]="{ status: 'all' }"
                (click)="toggleSideMenu(false)"
                aria-label="Go to My Staking">My Staking
        </button>
        <dl class="user-profile-area">
            <dt class="user-img">
                <a [href]="goToP5Profile()">
                    <img [src]="sanitizeImageUrl(playerInfo.imageUrl)"
                         #profileImg
                         (error)="profileImg.src = defaultProfileImage"
                         [alt]="playerInfo.displayName"
                         [title]="playerInfo.displayName"
                         class="profile-img">
                </a>
            </dt>
            <dd class="user-nickname">
                <a href="#userMenu" (click)="$event.preventDefault(); profileToggleMenu.toggle($event)" class="pc">
                    <span>{{ playerInfo.displayName }}</span>
                </a>
                <a [href]="goToP5Profile()" class="mobile">
                    {{ playerInfo.displayName }}
                </a>

                <p-overlayPanel id="userMenu" #profileToggleMenu [style]="{width: '338px', padding: 0}">
                    <app-account-menu (closeAccountMenu)="profileToggleMenu.toggle(false)"></app-account-menu>
                </p-overlayPanel>
            </dd>
        </dl>
    </section>

    <section class="logout-area" *ngIf="isInitialize && !isSignedIn" [@visibleCloseMenuButton]="isInitialize && !isSignedIn">
        <a class="sign-in GA-SignIn" routerLink="/login" (click)="toggleSideMenu(false)">Sign In</a>
        <a class="sign-up GA-SignUp" routerLink="/register" (click)="toggleSideMenu(false)">Sign Up</a>
    </section>
</header>
