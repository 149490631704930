import * as dayjs from 'dayjs';
import { VM } from '@models';

export class DateConvertor {
    public static getUtcDateTime(d: any) {
        return dayjs.utc(d).add(dayjs(d).utcOffset(), 'minutes');
    }

    public static getNowUtc(d: any) {
        return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    }

    public static getIntervalExpireDday(expireDate: string, serverTime: any, dDayFormat: string = null): VM.ExpireDDayResponse {
        const returnData = {
            isExpired: false,
            dDay: ''
        };

        if (expireDate) {
            const expireTime = dayjs.utc(expireDate);
            const currentTime = dayjs.utc(serverTime);

            if (expireTime.diff(currentTime) >= 0) {
                const remainingTime = dayjs.utc(expireTime.diff(currentTime));
                const days = expireTime.diff(currentTime, 'days');
                returnData.isExpired = false;
                if (dDayFormat) {
                    returnData.dDay = remainingTime.format(dDayFormat);
                } else {
                    returnData.dDay = (days >= 1 ? `${days} Days ` : '') + remainingTime.format('HH:mm:ss [Left]');
                }
            } else {
                returnData.isExpired = true;
                returnData.dDay = '00:00:00 Left';
            }
        }

        return returnData;
    }
}
