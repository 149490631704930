import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DTO } from '@models';
import { map, tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ServerTimeService } from '@core/services/server-time.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public constructor(private http: HttpClient, private serverTimeService: ServerTimeService) {}

    private getParameterFormatting(options) {
        let params = new HttpParams();
        Object.keys(options).forEach((key) => {
            if (options[key]) {
                params = params.append(key, options[key]);
            }
        });
        return params;
    }

    // 토큰 발급
    public login(data: DTO.FrontOffice.Member.LoginRequest) {
        return this.http
            .post<DTO.FrontOffice.Member.LoginResponse>('/auth', data, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }

    // refresh token
    public reissueToken(data: DTO.FrontOffice.Member.ReFreshTokenRequest) {
        return this.http.post<DTO.FrontOffice.Member.LoginResponse>(`/auth/refresh`, data).toPromise();
    }

    // forgot password
    public findPassword(data: DTO.FrontOffice.Member.FindPasswordRequest) {
        return this.http.post<void>(`/members/${data.email}/password/reset`, data).toPromise();
    }

    // confirm password
    public confirmPassword(data: DTO.FrontOffice.Common.ConfirmPasswordRequest) {
        return this.http.patch<void>(`/AccountSettings/ConfirmPassword`, data).toPromise();
    }

    // change password
    public changePassword(data: DTO.FrontOffice.Common.ChangePasswordRequest) {
        return this.http.patch<void>(`/AccountSettings/ChangePassword`, data).toPromise();
    }

    // 회원가입 - email 검증
    public registerEmailValid(data: DTO.FrontOffice.Member.EmailValidRequest) {
        const params = this.getParameterFormatting(data);
        return this.http.get<DTO.FrontOffice.Member.EmailValidResponse>(`/members/check`, { params }).toPromise();
    }

    // 회원가입
    public register(data: DTO.FrontOffice.Member.SignUpRequest) {
        return this.http.post<DTO.FrontOffice.Member.SignUpResponse>(`/members`, data).toPromise();
    }

    // 메일 재발송
    public resendEmail(data: DTO.FrontOffice.Member.ResendEmailRequest) {
        return this.http.post<void>(`/verify/resend`, data).toPromise();
    }

    // 인증메일발송 (중복회원)
    public requestVerify() {
        return this.http.post<DTO.FrontOffice.Member.RequestVerifyResponse>(`/members/requestVerify`, {}).toPromise();
    }

    // 메일 > auth valification, 비번변경
    public authVerification(data: DTO.FrontOffice.Member.VerifyRequest) {
        return this.http.post<void>(`/verify`, data).toPromise();
    }

    // 메일 변경
    public changeEmail(data: DTO.FrontOffice.Member.ChangeEmailRequest) {
        return this.http.post<DTO.FrontOffice.Member.ChangeEmailResponse>(`/members/email`, data).toPromise();
    }

    // 메일변경 취소
    public cancelChangeEmail() {
        return this.http.post<void>(`/members/email/cancel`, {}).toPromise();
    }

    // 인증메일 전송(코인출금)
    public sendVerificationEmailRequest(data: DTO.FrontOffice.Common.SendVerificationEmailRequest) {
        return this.http
            .post<DTO.FrontOffice.Common.SendVerificationEmailResponse>(`/Common/SendVerificationEmailRequest`, data, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }

    // 로그아웃
    public signOut() {
        return this.http.post<void>(`/logout`, {}).toPromise();
    }
}
