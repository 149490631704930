import { DTO } from '@models';
import { environment } from '@environments/environment';

export class Page {
    public static environment = environment;

    public static goToP5Page(path: string | null = null) {
        if (!path) {
            return `${this.environment.p7Host}/`;
        }
        return `${this.environment.p7Host}/${path}`;
    }

    public static goToP5Profile(playerInfo: DTO.FrontOffice.Member.GetMyInfoResponse) {
        return `${this.environment.p7Host}/profiles/${playerInfo.displayName?.toLowerCase()}/`;
    }

    public static goToP5ProfilePage(userName: string) {
        const convertedUserName = userName.toLowerCase().replace(/ /g, '-');
        return `${this.environment.p7Host}/profiles/${convertedUserName}/`;
    }

    public static goToP5SellerProfile(sellerName: string) {
        return `${this.environment.p7Host}/profiles/${sellerName}/`;
    }

    public static goToP5Admin(path: string | null = null) {
        if (!path) {
            return `${this.environment.pocketFives}/`;
        }
        return `${this.environment.pocketFives}/${path}`;
    }

    public static goToYoutubePlayer(videoId: string) {
        return `https://www.youtube.com/watch?v=${videoId}`;
    }
}
