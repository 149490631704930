import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'ldt',
    standalone: true
})
export class LocalDtPipe extends DatePipe implements PipeTransform {
    public transform(value: any, format = 'y-MM-dd'): any {
        return super.transform(dayjs.utc(value).format(), format);
    }
}
