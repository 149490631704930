import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { DTO } from '@models';
import { MaintenanceService } from '@core/services/maintenance.service';
import { PlayerStateService } from '@core/states/player-state.service';

export const accessGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const maintenanceService = inject(MaintenanceService);
    const playerStateService = inject(PlayerStateService);

    if (maintenanceService.isP5Maintenance() && !maintenanceService.config.getValue()?.isInDevIp) {
        return router.navigate(['maintenance']);
    }

    const isPassGuard = state.url.includes('logout') || state.url.includes('verify');
    if (isPassGuard) {
        return true;
    }

    // 로그인 체크
    const isLogin = playerStateService.isSignedIn.getValue();

    if (isLogin) {
        // login, register 로그인 된 상태에서는 메인으로
        if (route.data.requireLogout) {
            router.navigate(['']);
            return false;
        }

        let playerInfo = playerStateService.info.getValue();

        // 새로고침해서 login 된 상태(쿠키)인데 playerInfo가 없을때 or 토큰없을때는 다시 발급
        if (!playerInfo || !playerStateService.accessToken.getValue()) {
            const success = await playerStateService.reissueToken();
            // 실패하면 로그아웃 되니 메인으로;
            if (!success) {
                router.navigate(['']);
                return false;
            }
            playerInfo = playerStateService.info.getValue();
        }

        // 가입 인증전, 인증전 중복계정, 인증된 계정이 있는 중복계정, 기존 인증전 p5계정.. 이메일변경페이지에서 변경 된 것은 pass
        if (!state.url.includes('register-before-verification') && playerInfo.verification && DTO.Enums.Common.LoginVerificationStatus.ChangingEmail !== playerInfo.verification.status) {
            router.navigate(['/register-before-verification']);
            return false;
        }

        return true;
    }

    // wallet, my-staking, register-before-veri.., delete-account 등 로그인 된 상태에서만 접근가능
    if (!isLogin && route.data.requireLogin) {
        router.navigate(['login']);
        return false;
    }

    return true;
};
