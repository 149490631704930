import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StakingFilterToggleStateService {
    private window: Window;
    public isVisibleFilterPanel: boolean;
    public isSelectedStakingFilter = new BehaviorSubject<boolean>(false);

    public constructor(@Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
    }

    public onToggleFilterPanel() {
        this.isVisibleFilterPanel = !this.isVisibleFilterPanel;
        this.controlScroll();
    }

    public onCloseFilterPanel() {
        this.isVisibleFilterPanel = false;
        this.controlScroll();
    }

    private controlScroll() {
        if (this.isVisibleFilterPanel) {
            this.window.document.body.classList.add('inModal');
        } else {
            this.window.document.body.classList.remove('inModal');
        }
    }
}
