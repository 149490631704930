import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { VM } from '@models';
import { Router } from '@angular/router';
import { ServerTimeService } from '@core/services/server-time.service';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {
    public isCheckIp = false;
    public config = new BehaviorSubject<VM.MaintenanceConfigResponse>(null);

    public constructor(private http: HttpClient, private router: Router, private serverTimeService: ServerTimeService) {}

    public getMaintenanceInfoRequest() {
        const timestamp = new Date().getTime();
        return this.http.get<VM.MaintenanceConfigResponse>(`${environment.r2Host}/maintenance.json?_=${timestamp}`).toPromise();
    }

    public getIPAddress() {
        return this.http.get<any>('/Common/GetClientIp').toPromise();
    }

    // 회사 내부 ip 체크
    public async isInDevIp() {
        try {
            const res = await this.getIPAddress();
            // Ram IP : 34.212.34.137
            return ['34.212.34.137', '220.117.201.193', '112.169.173.1', '112.169.173.2'].includes(res?.clientIp);
        } catch (e) {
            this.router.navigate(['maintenance']);
            return false;
        }
    }

    // 메인터넌스 체크
    public async maintenanceCheck() {
        try {
            const config = await this.getMaintenanceInfoRequest();

            let isInDevIp = this.config.getValue()?.isInDevIp || false;

            if (config.p5.isMaintenance && !this.isCheckIp) {
                isInDevIp = await this.isInDevIp();
            }

            this.config.next({ isInDevIp, ...config });

            if (this.isP5Maintenance() && !isInDevIp) {
                this.router.navigate(['maintenance']);
            }
        } catch (err) {
            console.error(err);
            // this.router.navigate(['maintenance']);
        } finally {
            this.isCheckIp = true;
        }
    }

    // p5 메인터넌스 : 내부ip 체크, active, 점검시간 체크
    public isP5Maintenance() {
        const config = this.config.getValue();

        return Boolean(config?.p5?.isMaintenance) && this.isInMaintenancePeriod(config?.p5?.startDate, config?.p5?.endDate);
    }

    // 점검시간 체크
    private isInMaintenancePeriod(start, end) {
        const startDate = dayjs.utc(start);
        const endDate = dayjs.utc(end);

        return this.serverTimeService.current.isAfter(startDate) && this.serverTimeService.current.isBefore(endDate);
    }
}
