import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VM } from '@models';
import { PaymentProcessStatusResponseMessage } from '@models/vm/vm';

@Injectable({
    providedIn: 'root'
})
export class UpdatePaymentService {
    public updatePaymentMessage = new BehaviorSubject<VM.PaymentProcessStatusResponseMessage>(null);
    public constructor() {}

    public emptyUpdatePayment() {
        this.updatePaymentMessage.next(null);
    }

    public destroyUpdatePayment() {
        this.updatePaymentMessage.unsubscribe();
    }
}
