export enum BuyInType {
    Low = 'Low',
    Middle = 'Middle',
    High = 'High',
    SuperHigh = 'SuperHigh',
    Vip = 'Vip'
}

export enum CurrencySymbol {
    USD = '$',
    EUR = '€',
    GBP = '£',
    RUB = '₽',
    JPY = '¥',
    CNY = '¥',
    KRW = '₩'
}

export enum PasswordStrength {
    None,
    VeryWeak,
    Soso,
    Good,
    Great
}

export enum PasswordStrengthDescription {
    None = 'Very weak',
    VeryWeak = 'Very weak',
    Soso = 'So-so',
    Good = 'Good',
    Great = 'Great'
}

export enum AccountGroup {
    Guests = 2,
    Members = 3,
    Administrators = 4,
    SuperModerators = 12,
    Moderators = 13,
    PaidCoaches = 14,
    PremiumCoaches = 15,
    P5sSuperMod = 16,
    PreFlops = 17,
    Blogger = 19,
    ContentManager = 20
}
