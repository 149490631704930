export interface MerchantAuthentication {
    name: string;
    transactionKey: string;
}

export interface CreditCard {
    cardNumber: string;
    expirationDate: string;
    cardCode: string;
    cardType: string;
}

export interface Payment {
    creditCard: CreditCard;
}

export interface PaymentProfile {
    paymentProfileId: string;
    customerType: string;
    payment: Payment;
}

export interface ProfileToPayment {
    merchantCustomerId: string;
    customerProfileId: string;
    customerPaymentProfileId: string;
    paymentProfile: PaymentProfile;
    paymentProfiles: PaymentProfile[];
}

export interface ProfileToCustomer {
    merchantCustomerId: string;
    description: string;
    email: string;
    paymentProfiles: PaymentProfile;
}

export interface TransactionRequest {
    transactionType: string;
    amount: string;
    currencyCode: string;
    profile: ProfileToPayment;
    payment: Payment;
    customerIP: string;
    refTransId: string;
}

export interface AuthorizeNetRequestBase {
    merchantAuthentication: MerchantAuthentication;
}

export interface AuthenticateTestRequest extends AuthorizeNetRequestBase {

}

export interface CreateTransactionRequest extends AuthorizeNetRequestBase {
    refId: string;
    transactionRequest: TransactionRequest;
}

export interface GetTransactionDetailsRequest extends AuthorizeNetRequestBase {
    transId: string;
}

export interface CreateCustomerProfileRequest extends AuthorizeNetRequestBase {
    profile: ProfileToCustomer;
}

export interface CreateCustomerPaymentProfileRequest extends AuthorizeNetRequestBase {
    customerProfileId: string;
    paymentProfile: PaymentProfile;
}

export interface GetCustomerProfileRequest extends AuthorizeNetRequestBase {
    customerProfileId: string;
}

export interface DeleteCustomerPaymentProfileRequest extends AuthorizeNetRequestBase {
    customerProfileId: string;
    customerPaymentProfileId: string;
}

export interface Message {
    code: string;
    text: string;
    description: string;
}

export interface Messages {
    resultCode: string;
    message: Message[];
}

export interface AuthorizeNetResponseBase {
    refId: string;
    messages: Messages;
}

export interface GetCustomerProfileResponse extends AuthorizeNetResponseBase {
    profile: ProfileToPayment;
}

export interface Error {
    errorCode: string;
    errorText: string;
}

export interface ResponseResult {
    messages: Message[];
    errors: Error[];
}

export interface TransactionResponse extends ResponseResult {
    transId: string;
    authCode: string;
    transactionStatus: string;
    accountNumber: string;
    accountType: string;
    authAmount: number;
    profile: ProfileToPayment;
    payment: Payment;
    customer: ProfileToCustomer;
}

export interface CreateTransactionResponse extends AuthorizeNetResponseBase {
    transactionResponse: TransactionResponse;
}

export interface GetTransactionResponse extends AuthorizeNetResponseBase {
    transactionResponse: TransactionResponse;
}

export interface CreateCustomerProfileResponse extends AuthorizeNetResponseBase {
    customerProfileId: string;
    customerPaymentProfileIdList: string[];
}

export interface CreateCustomerPaymentProfileResponse extends AuthorizeNetResponseBase {
    customerProfileId: string;
    customerPaymentProfileId: string;
}

export interface WebhooksRequest {
    notificationId: string;
    eventType: string;
    webhookId: string;
}

export interface WebhooksPayloadBase {

}

export interface WebhooksPayloadPayment extends WebhooksPayloadBase {
    responseCode: number;
    authAmount: number;
    merchantReferenceId: string;
    id: string;
}

export enum TransactionStatus {
    AUTHORIZEDPENDINGCAPTURE,
    CAPTUREDPANDINGSETTLEMENT
}
