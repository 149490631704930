<ul class="navbar-submenu" [class.open]="isOpen">
    <li *ngIf="isInNavBar" class="menu-back">
        <a (click)="closeSubMenu.emit()"><i class="fas fa-angle-left"></i>Back</a>
    </li>
    <li *ngIf="!isInNavBar" class="title">Content</li>
    <li class="sub-menu-item"><a [href]="goToP5Profile()" title="Go to your profile">Profile</a></li>
    <li class="sub-menu-item"><a [routerLink]="['/my-staking', isSeller ? 'sold' :'bought']" routerLinkActive="active" (click)="onCloseSideMenu();">My Staking</a></li>
    <li class="sub-menu-item"><a routerLink="/manage-follow-player" routerLinkActive="active" (click)="onCloseSideMenu();">Manage Following Player</a></li>

    <li *ngIf="!isInNavBar" class="title">Settings</li>
    <li class="sub-menu-item"><a routerLink="/account-settings" title="Edit account settings" (click)="onCloseSideMenu();">Account Settings</a></li>

    <li class="sub-menu-item" *ngIf="isModeratorCPMenu" [class.top-line]="!isInNavBar"><a [href]="goToP5Page('modcp/')">ModeratorCP</a></li>
    <li class="sub-menu-item" *ngIf="isWPAminMenu"><a [href]="goToAdminWP('52UBVvWT/wp-admin')" target="_blank" rel="noopener"><i class="fa fa-lock"></i> WP Admin</a></li>
    <li class="sub-menu-item" *ngIf="isAdminCPMenu"><a [href]="goToAdminCP()" target="_blank" rel="noopener"><i class="fa fa-lock"></i> AdminCP</a></li>

    <li *ngIf="!isInNavBar" class="top-line"><a (click)="logout()">Sign Out</a></li>
</ul>
