<nav class="navbar" *ngIf="isInitialized" [class.login]="isSignedIn">
    <ul class="navbar-menu">
        <li *ngIf="isSignedIn && playerInfo" class="nav-item mobile-account-menu dropdown" (mouseleave)="closeSubMenu()">
            <a (click)="openSubMenu(p5HasSubMenu.Account)">Account <i class="fas fa-angle-right"></i></a>
            <app-account-menu [isInNavBar]="true" [isOpen]="selectedSubMenu === p5HasSubMenu.Account" (closeSubMenu)="closeSubMenu()" (closeAccountMenu)="onCloseSideMenu()"></app-account-menu>
        </li>
        <li class="nav-item">
            <a [class.active]="isOnSponsorshipMenu" (click)="$event.preventDefault(); onCloseSideMenu()" routerLink="/staking/poker-staking" href="#staking">Staking</a>
        </li>
        <li class="nav-item">
            <a [href]="goToP5Page('news/')">Poker Stories</a>
        </li>
        <li class="nav-item dropdown" (mouseleave)="closeSubMenu()">
            <a href="#rakings" (click)="$event.preventDefault(); openSubMenu(p5HasSubMenu.Rankings)">Rankings <i class="fas fa-angle-right"></i></a>
            <ul class="navbar-submenu" *ngIf="isInitialize" [class.open]="selectedSubMenu === p5HasSubMenu.Rankings" [class.login]="isSignedIn">
                <li class="menu-back">
                    <a href="#back" (click)="$event.preventDefault(); closeSubMenu()"><i class="fas fa-angle-left"></i>Back</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('rankings/')" title="Online Poker Rankings">Online Poker Rankings</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('leaderboard/sliding/')" title="Sliding Plb">Sliding Plb</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('leaderboard/yearly/')" title="Player of the Year">Player of the Year</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('leaderboard/monthly/')" title="Player of the Month">Player of the Month</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('sortable-rankings/')" title="Player of the Month">Sortable rankings</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('country-rankings/')" title="Player of the Month">Country rankings</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('top-online-poker-players/')" title="Player of the Month">Former #1 Players</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('faq#rankings-tracked/')" title="Player of the Month">Rankings FAQ</a>
                </li>
                <li class="sub-menu-item">
                    <a [href]="goToP5Page('leaderboard/all-time/')" title="Player of the Month">All-Time Earnings</a>
                </li>
                <li class="sub-menu-item dropdown"
                    (mouseenter)="openTwoDepthSubMenu($event, p5HasTwoDepthSubMenu.Badges)"
                    (mouseleave)="closeSubMenu(true)">
                    <a (click)="openTwoDepthSubMenu($event, p5HasTwoDepthSubMenu.Badges)" href="#badges">Badges <i class="fas fa-angle-right"></i></a>
                    <ul class="navbar-submenu" [class.open]="selectedTwoDepthSubMenu === p5HasTwoDepthSubMenu.Badges">
                        <li class="sub-menu-item">
                            <a [href]="goToP5Page('badges/100k/')" title="Player of the Month">$ 100k Cashes</a>
                        </li>
                        <li class="sub-menu-item">
                            <a [href]="goToP5Page('badges/500k/')" title="Player of the Month">$ 500k cashes</a>
                        </li>
                        <li class="sub-menu-item">
                            <a [href]="goToP5Page('badges/1-million/')" title="Player of the Month">$1 Million Cashes</a>
                        </li>
                        <li class="sub-menu-item">
                            <a [href]="goToP5Page('badges/5-million/')" title="Player of the Month">$5 Million Cashes</a>
                        </li>
                        <li class="sub-menu-item">
                            <a [href]="goToP5Page('badges/10-million/')" title="Player of the Month">$10 Million Cashes</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li *ngIf="isSignedIn && playerInfo" class="nav-item mobile-signout"><a (click)="$event.preventDefault(); onCloseSideMenu(); logout();" href="#logout">Sign Out</a></li>
    </ul>
</nav>
