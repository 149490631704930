import { Injectable } from '@angular/core';

import { finalize, takeUntil, tap } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimerService {
    private timer = interval(1000);
    private timerStarted = false;
    private subject = new Subject<number>();
    private unsubscribe = new Subject<void>();

    public get started() {
        return this.timerStarted;
    }

    public start() {
        if (this.timerStarted) {
            return;
        }

        this.timer.pipe(
            takeUntil(this.unsubscribe),
            tap(() => {
                this.timerStarted = true;
            }),
            finalize(() => {
                this.timerStarted = false;
            })
        ).subscribe(n => {
            this.subject.next(n);
        });
    }

    public stop() {
        if (!this.timerStarted) {
            return;
        }

        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public get() {
        return this.subject;
    }
}
