import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { DOCUMENT, NgIf } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { DTO, VM } from '@models';
import dayjs from 'dayjs';
import { environment } from '@environments/environment';
import { NavbarComponent } from '@shared/components/layout/navbar/navbar.component';
import { AccountMenuComponent } from '@shared/components/layout/account-menu/account-menu.component';
import { MaintenanceNoticeComponent } from '@shared/components/layout/maintenance-notice/maintenance-notice.component';
import { PlayerStateService } from '@core/states/player-state.service';
import { PlayerService } from '@core/services/player.service';
import { TimerService } from '@core/services/timer.service';
import { ServerTimeService } from '@core/services/server-time.service';
import { SanitizerService } from '@shared/states/sanitizer.service';
import { StakingFilterToggleStateService } from '@shared/states/staking-filter-toggle-state.service';
import { PipeModule } from '@shared/pipes/pipe.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Page } from '@helpers/page';
import { DateConvertor } from '@helpers/date';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [AccountMenuComponent, NgIf, NavbarComponent, MaintenanceNoticeComponent, PipeModule, OverlayPanelModule, RouterLink],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('visibleCloseMenuButton', [
            state('true', style({ opacity: 1, visibility: 'visible' })),
            state('void', style({ opacity: 0, visibility: 'hidden' })),
            transition(':enter', [animate('200ms ease-in-out')]),
            transition(':leave', [animate('0ms')])
        ]),
        trigger('closeHeaderNotice', [
            state('true', style({ opacity: 1, visibility: 'visible', marginTop: '0px' })),
            state('void', style({ opacity: 0, visibility: 'hidden', marginTop: '-40px' })),
            transition(':enter', [animate('0ms')]),
            transition(':leave', [animate('200ms ease-in-out')])
        ])
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    private isPendingCheckBalance = false;

    public isWalletPage = false;
    public environment = environment;
    public isSignedIn = false;
    public playerInfo: DTO.FrontOffice.Member.GetMyInfoResponse;
    public balance: VM.UpdateUserBalance;
    public isSeller: boolean;
    public isShowSideMenu = false;
    public isInitialize = false;
    public isSelectedStakingFilter = false;
    public defaultProfileImage = `${environment.imageHost}/common/ico_default_profile.svg`;

    public get isHeaderNotice() {
        const isHeaderNoticeCloseCookie = this.playerState.getIsHeaderNoticeCookie();
        const { isExpired } = DateConvertor.getIntervalExpireDday(this.playerInfo?.verification?.expireDate, this.serverTime.current);

        return !isHeaderNoticeCloseCookie && DTO.Enums.Common.LoginVerificationStatus.ChangingEmail === this.playerInfo?.verification?.status && !isExpired;
    }

    public constructor(
        @Inject(DOCUMENT) public document: Document,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private timer: TimerService,
        private serverTime: ServerTimeService,
        private playerService: PlayerService,
        private playerState: PlayerStateService,
        private stakingFilterToggleStateService: StakingFilterToggleStateService,
        private sanitizerService: SanitizerService
    ) {
        this.balance = {
            value: 0,
            isUpdate: false
        };
    }

    public ngOnInit() {
        // this.document.defaultView.alert(this.document.defaultView.navigator.serviceWorker);
        // console.log(this.document.defaultView.navigator.serviceWorker);

        this.initData();

        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                startWith(this.router),
                takeUntil(this.unsubscribe)
            )
            .subscribe((event: NavigationEnd) => {
                if (event instanceof NavigationEnd) {
                    this.isWalletPage = event.url.startsWith('/wallet');
                    this.cdRef.markForCheck();
                }
            });

        this.stakingFilterToggleStateService.isSelectedStakingFilter.pipe(takeUntil(this.unsubscribe)).subscribe((isSelectedStakingFilter) => {
            this.isSelectedStakingFilter = isSelectedStakingFilter;
            this.cdRef.detectChanges();
        });

        this.isInitialize = true;
        this.cdRef.markForCheck();
    }

    private initData() {
        combineLatest([this.playerState.info, this.playerState.balance])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                [this.playerInfo, this.balance] = data;
                this.cdRef.markForCheck();
            });

        this.playerState.isSignedIn.pipe(takeUntil(this.unsubscribe)).subscribe(async (isSignedIn) => {
            this.isSignedIn = isSignedIn;
            this.cdRef.markForCheck();
        });
        this.playerState.accessToken.pipe(takeUntil(this.unsubscribe)).subscribe(async (accessToken) => {
            if (!!accessToken) {
                await this.setPlayerBalance();
            }
            this.cdRef.markForCheck();
        });
        this.playerState.info.pipe(takeUntil(this.unsubscribe)).subscribe(async (playerInfo) => {
            this.isSeller = playerInfo && playerInfo.memberClass === DTO.Enums.Common.MemberClass.Seller;
            this.cdRef.markForCheck();
        });

        this.timer.get().pipe(takeUntil(this.unsubscribe)).subscribe(this.tick);
    }

    private tick = async () => {
        if (!this.serverTime.current) {
            return;
        }

        this.serverTime.current = dayjs.utc(this.serverTime.current).add(1, 'second');
        // this.serverTime.current.add(1, 's').unix();

        // if (0 === this.serverTime.timestamp % 10) {
        //     await this.setPlayerBalance();
        // }
    };

    private async setPlayerBalance() {
        if (this.isSignedIn && !this.isPendingCheckBalance) {
            try {
                this.isPendingCheckBalance = true;
                const res = await this.playerService.getPlayerBalance();

                this.balance.value = res.balance;
                this.playerState.balance.next({ value: res.balance, isUpdate: false });
            } catch (e) {
                console.error('fail to check balance', e);
            } finally {
                this.isPendingCheckBalance = false;
                this.cdRef.markForCheck();
            }
        }
    }

    public sanitizeImageUrl(resource: string) {
        return this.sanitizerService.sanitizeImageUrl(resource);
    }

    public toggleSideMenu(isToggle) {
        this.isShowSideMenu = isToggle;
        this.cdRef.detectChanges();
    }

    public goToP5Profile() {
        return Page.goToP5Profile(this.playerInfo);
    }

    public onToggleFilterPanel() {
        this.stakingFilterToggleStateService.onToggleFilterPanel();
    }

    /**
     * 모바일 필터 아이콘노출
     */
    public get isVisibleToggleFilterButton() {
        return this.router.url.includes('poker-staking') || this.router.url.includes('search');
    }

    public updateBalanceAnimationEnd() {
        this.balance.isUpdate = false;
    }

    public closeHeaderNotice() {
        this.playerState.setIsHeaderNoticeCookie();
    }

    public goMain() {
        // 메인에서 logo 눌렀을때도 새로 로드하기 위함
        this.router.navigateByUrl('/staking', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/']);
        });
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
