import { Routes } from '@angular/router';
import { accessGuard } from '@core/guards/access.guard';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main/main.routes').then((r) => r.routes),
        data: {
            title: 'Home'
        }
    },
    {
        path: 'staking',
        loadChildren: () => import('./pages/staking/staking.routes').then((r) => r.routes),
        data: {
            title: 'Staking'
        }
    },
    {
        path: 'wallet',
        loadChildren: () => import('./pages/wallet/wallet.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'Payment'
        }
    },
    {
        path: 'my-staking',
        loadChildren: () => import('./pages/my-staking/my-staking.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'My Staking'
        }
    },
    {
        path: 'account-settings',
        loadChildren: () => import('@app/pages/account-settings/account-settings.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'Settings'
        }
    },
    {
        path: 'manage-follow-player',
        loadComponent: () => import('./pages/manage-follow-player/manage-follow-player.component').then((m) => m.ManageFollowPlayerComponent),
        canActivate: [accessGuard],
        data: {
            title: 'Manage Following Player',
            requireLogin: true
        }
    },
    {
        path: 'external',
        loadChildren: () => import('./pages/external/external.routes').then((r) => r.routes)
    },
    {
        path: 'error',
        loadComponent: () => import('@app/pages/errors/error.component').then((m) => m.ErrorComponent)
    },
    {
        path: 'maintenance',
        loadComponent: () => import('@app/pages/maintenance/maintenance.component').then((m) => m.MaintenanceComponent)
    },
    {
        path: '',
        loadChildren: () => import('./pages/member/member.routes').then((m) => m.routes),
        canActivate: [accessGuard],
        data: {
            title: 'Member'
        }
    },
    // {
    //     path: 'articles',
    //     loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesModule),
    //     canActivate: [AccessGuard],
    //     data: {
    //         title: 'Articles'
    //     }
    // },
    {
        path: '**',
        redirectTo: ''
    }
];
