import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class ServerTimeService {
    private _current: dayjs.Dayjs;

    public get current(): dayjs.Dayjs {
        return this._current || dayjs.utc();
    }

    public set current(value: dayjs.Dayjs) {
        this._current = value;
    }

    public get timestamp() {
        return !!this.current && this.current.unix();
    }

    public constructor() {}
}
