import { inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Router } from '@angular/router';

import { catchError, from, lastValueFrom, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { DTO } from '@models';
import { v1 as uuidv1 } from 'uuid';
import { TimerService } from '@core/services/timer.service';
import { PlayerStateService } from '@core/states/player-state.service';

export const httpHostInterceptor: HttpInterceptorFn = (request, next) => {
    const guidStorageKey = 'additionalAuthorizationStore';
    const router = inject(Router);
    const document = inject(DOCUMENT);
    const timer = inject(TimerService);
    const subscriber = new Subject<void>();
    const playerStateService = inject(PlayerStateService);

    if (request.url.includes(environment.stream) || request.url.includes('assets/js')) {
        return next(
            request.clone({
                url: request.url,
                withCredentials: true
            })
        ).pipe(
            takeUntil(subscriber),
            catchError((error: HttpErrorResponse) => throwError(() => error))
        );
    }

    let endPoint = environment.backendHost;
    if (request.url.includes('/Payment/') || request.url.includes('/PaymentAccount/')) {
        endPoint = environment.paymentHost;
    } else if (request.url.includes('//')) {
        endPoint = '';
    }

    // p5 인증 필요 없는 api는 false
    let isWithCredentials = true;
    if ((request.url.includes(environment.p5BackendHost) && !request.url.includes('logout')) || request.url.includes(environment.r2Host)) {
        isWithCredentials = false;
    }

    // GUID 필요한 api
    if ('/auth' === request.url || '/members' === request.url) {
        let guid = document.defaultView.localStorage.getItem(guidStorageKey);

        if (!guid) {
            guid = uuidv1();
            document.defaultView.localStorage.setItem(guidStorageKey, guid);
        }

        return next(
            request.clone({
                url: `${endPoint}${request.url}`,
                withCredentials: true,
                headers: request.headers.set('Authorization-EX', guid)
            })
        );
    }

    return next(
        request.clone({
            url: `${endPoint}${request.url}`,
            withCredentials: isWithCredentials,
            headers: playerStateService.accessToken.getValue() && !request.url.includes(environment.r2Host) ? request.headers.set('Authorization', `Bearer ${playerStateService.accessToken.getValue()}`) : null
        })
    ).pipe(
        catchError((error: HttpErrorResponse) => {
            let message = `Error Status: ${error.status}\nMessage: `;
            const signOutErrorCodes = [DTO.Enums.Common.ErrorCodes.SESSION_EXPIRED, DTO.Enums.Common.ErrorCodes.INVALID_SESSION, DTO.Enums.Common.ErrorCodes.INVALID_PLAYER];

            if (signOutErrorCodes.includes(error?.error?.errorCode)) {
                message += error.error.description;
                timer.stop();
                router.navigateByUrl('/logout');
            } else {
                message += error.message;
            }

            if (error instanceof HttpErrorResponse && 401 === error.status) {
                return from(playerStateService.reissueToken()).pipe(
                    switchMap((success) => {
                        if (success) {
                            return lastValueFrom(
                                next(
                                    request.clone({
                                        url: `${endPoint}${request.url}`,
                                        withCredentials: true,
                                        headers: playerStateService.accessToken.getValue() ? request.headers.set('Authorization', `Bearer ${playerStateService.accessToken.getValue()}`) : null
                                    })
                                )
                            );
                        }
                    })
                );
            }
            console.error('Error message', message);
            return throwError(error);
        })
    );
};
