import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
    name: 'money',
    standalone: true
})
export class MoneyPipe implements PipeTransform {
    public transform(value: string | number, forcedDecimal = true, currencyCode = 'USD', isNotConvert = false) {
        let converted;

        if (!value) {
            converted = `${this.currencySymbol(currencyCode)}0`;
            if (forcedDecimal) {
                converted = `${converted}.00`;
            }
            return converted;
        }

        if ('number' === typeof value) {
            value = value.toString();
        }

        if (value.includes('.')) {
            const decimalInfo = value.split('.');
            const formatValue = this.numberFormat(decimalInfo[0]);
            const digit = `${decimalInfo[1]}0`.slice(0, 2);
            converted = `${formatValue}.${digit}`;
            if (!forcedDecimal) {
                converted = isNotConvert ? this.numberFormat(Number(value).toFixed(2)) : this.numberFormat(Number(value).toFixed(0));
            }
        } else {
            converted = this.numberFormat(value);
            if (forcedDecimal) {
                converted = `${converted}.00`;
            }
        }

        let sign = '';
        if (converted.startsWith('-')) {
            sign = '-';
            converted = converted.replace('-', '');
        }

        return `${sign}${this.currencySymbol(currencyCode)}${converted}`;
    }

    private numberFormat(value: string) {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    private currencySymbol(currencyCode) {
        if ('AUD' === currencyCode) {
            return 'A$';
        }
        if ('CAD' === currencyCode) {
            return 'Can$';
        }
        if ('TWD' === currencyCode) {
            return 'NT$';
        }

        return getCurrencySymbol(currencyCode, 'narrow');
    }
}
