import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { httpHostInterceptor } from '@core/interceptors/http-host.interceptor';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideServiceWorker('serviceWorker.js', {
            enabled: true,
            registrationStrategy: 'registerImmediately'
            // enabled: environment.production
        }),
        provideHttpClient(withInterceptors([httpHostInterceptor])),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideRouter(routes),
        DialogService,
        MessageService,
        ConfirmationService
    ]
};
