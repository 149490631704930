<footer class="footer-container">
    <div class="logo-menu-area">
        <h1 class="footer-logo">
            <img class="lazy-img"
                 [customObservable]="footerScrollContainer"
                 [lazyLoad]="environment.imageHost + '/staking/logo/pokerstake_logo.svg'"
                 alt="PokerStake" width="212" height="20">
        </h1>

        <ul class="link-list">
            <li><a [href]="goToP5Page('about-us/')" title="About Us more view">About Us</a></li>
            <li><a [href]="goToP5Page('contact/')" title="Contact Us more view">Contact Us</a></li>
            <li><a [href]="goToP5Page('dmca/')" title="DMCA Notice more view">DMCA Notice</a></li>
            <li><a [href]="goToP5Page('dmca/')" title="Terms of Service more view">Terms of Service</a></li>
        </ul>
    </div>

    <div class="etc-link-area">
        <ul class="sns-list">
            <li>
                <a href="https://www.facebook.com/pokerstake/" title="PokerStake Facebook">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-facebook.png'" alt="Facebook" width="28" height="28">
                </a>
            </li>
            <li>
                <a href="https://twitter.com/pokerstakecom" title="PokerStake X(Twitter)">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-twitter.png'" alt="X(Twitter)" width="28" height="28">
                </a>
            </li>
        </ul>
        <ul class="certify-list1">
            <li id="GPWA">
                <a href="#GPWA" (click)="openGPWAWindowPopup($event)" title="GPWA more view">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-gpwa.png'" width="75" height="28" alt="GPWA">
                </a>
            </li>
            <li>
                <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-rg.png'" width="28" height="28" alt="RG Gaming">
            </li>
        </ul>
        <ul class="certify-list2">
            <li>
                <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-18.png'" width="28" height="28" alt="18+">
            </li>
            <li>
                <a href="https://www.begambleaware.org/" title="be gamble aware">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-begambleaware.png'" width="165" height="21" alt="be gamble aware">
                </a>
            </li>
            <li>
                <a href="https://www.ncpgambling.org/" title="national council on problem gambling more view">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-ncpg.png'" width="70" height="21" alt="national council on problem gambling logo">
                </a>
            </li>
        </ul>
    </div>

    <address class="copyright">&copy; {{ year }} PokerStake.com <span class="text">Powered by Invision Community</span></address>
</footer>
