import { ChangeDetectionStrategy, Component, EventEmitter, Input, AfterContentInit, Output, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { Location, NgFor, NgIf } from '@angular/common';
import { environment } from '@environments/environment';
import { Page } from '@helpers/page';
import { DTO, VM } from '@models';
import { Router, RouterLink } from '@angular/router';
import { AccountMenuComponent } from '@shared/components/layout/account-menu/account-menu.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PlayerStateService } from '@core/states/player-state.service';

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [NgFor, NgIf, AccountMenuComponent, RouterLink],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements AfterContentInit {
    @Output() public toggleSidePanel = new EventEmitter<boolean>();
    @Input() public isSignedIn: boolean;
    @Input() public isInitialize: boolean;
    @Input() public playerInfo: DTO.FrontOffice.Member.GetMyInfoResponse;
    @Input() public set toggleSideMenu(isToggle: boolean) {
        this.isToggleSideMenu = isToggle;
    }

    public isToggleSideMenu: boolean;
    public p5HasSubMenu = VM.P5HasSubMenu;
    public p5HasTwoDepthSubMenu = VM.P5Has2DepthSubMenu;
    public selectedSubMenu: VM.P5HasSubMenu;
    public selectedTwoDepthSubMenu: VM.P5Has2DepthSubMenu;
    public isInitialized = false;
    public environment = environment;
    public isOnSponsorshipMenu: boolean;

    public constructor(private router: Router, private cdRef: ChangeDetectorRef, private location: Location, private sanitizer: DomSanitizer, private playerStateService: PlayerStateService) {}

    public ngAfterContentInit() {
        this.isInitialized = true;

        // Sponsorship 메뉴 active check (staking, my-staking, wallet에서 active)
        this.location.onUrlChange((url, state) => {
            this.isOnSponsorshipMenu = url.includes('staking') || url.includes('wallet');
            this.cdRef.markForCheck();
        });
        this.cdRef.markForCheck();
    }

    public goToP5Page(path: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(Page.goToP5Page(path));
    }

    public openSubMenu(menu: VM.P5HasSubMenu) {
        this.selectedSubMenu = menu;
    }

    public closeSubMenu(is2Depth = false) {
        if (is2Depth) {
            this.selectedTwoDepthSubMenu = null;
            return;
        }
        this.selectedSubMenu = null;
    }

    public openTwoDepthSubMenu(e: Event, menu: VM.P5Has2DepthSubMenu) {
        e.preventDefault();
        this.selectedTwoDepthSubMenu = menu;
    }

    public async logout() {
        await this.playerStateService.signOut();
        this.router.navigateByUrl('/');
    }

    public onCloseSideMenu() {
        this.toggleSidePanel.emit(false);
    }
}
