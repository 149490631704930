import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { Page } from '@helpers/page';
import { fromEvent, merge, Observable } from 'rxjs';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [LazyLoadImageModule],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [[{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }]]
})
export class FooterComponent implements OnInit {
    public footerScrollContainer: Observable<any>;
    public environment = environment;
    public year: number = new Date().getFullYear();
    public constructor(@Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer) {}

    public ngOnInit() {
        this.setScrollContainerForLazyLoadImage();
    }

    public goToP5Page(path: string = null) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(Page.goToP5Page(path));
    }

    public openGPWAWindowPopup(e) {
        e.preventDefault();
        this.document.defaultView.open('//certify.gpwa.org/verify/pocketfives.com/', 'gpwaPopup', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=640,height=680');
    }

    private setScrollContainerForLazyLoadImage() {
        this.footerScrollContainer = merge(fromEvent(this.document.defaultView, 'scroll'), fromEvent(this.document.defaultView, 'mouseover'));
    }
}
