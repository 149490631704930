import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, NgZone } from '@angular/core';
import { NgIf } from '@angular/common';
import { MaintenanceService } from '@core/services/maintenance.service';
import { ServerTimeService } from '@core/services/server-time.service';
import dayjs from 'dayjs';
import { LocalDtPipe } from '@shared/pipes/local-dt.pipe';

@Component({
    selector: 'app-maintenance-notice',
    templateUrl: './maintenance-notice.component.html',
    styleUrls: ['./maintenance-notice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LocalDtPipe]
})
export class MaintenanceNoticeComponent implements OnInit {
    private isP5Maintenance: boolean;
    public maintenanceStartTime: string;
    public isVisibleMaintenanceNotice: boolean;

    public constructor(private maintenanceService: MaintenanceService, private serverTimeService: ServerTimeService, private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

    public ngOnInit() {
        this.maintenanceService.config.subscribe((maintenance) => {
            this.isP5Maintenance = maintenance?.p5.isMaintenance;
            this.maintenanceStartTime = maintenance?.p5.startDate;

            const diffMaintenanceTime = dayjs.utc(maintenance?.p5.startDate).diff(this.serverTimeService.current) + 2000;
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    // 배너 삭제
                    this.maintenanceNoticeVisibleCheck();
                }, diffMaintenanceTime);
            });

            this.maintenanceNoticeVisibleCheck();
        });
    }

    // 점검 알림 : 점검30분전부터 시작시간까지 노출
    private maintenanceNoticeVisibleCheck() {
        const startDate = dayjs.utc(this.maintenanceStartTime);
        this.isVisibleMaintenanceNotice = this.isP5Maintenance && dayjs.utc(this.serverTimeService.current).isBetween(startDate.add(-30, 'minutes'), startDate);

        this.cdRef.markForCheck();
    }
}
