import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class P5Service {
    public constructor(private http: HttpClient) {}

    public signOut() {
        return this.http.get<any>(`${environment.p5BackendHost}/logout`).toPromise();
    }

    // youtube list
    public getMainYoutubeList() {
        return this.http.get<any>(`${environment.p5BackendHost}/staking/youtube/?playlistId=PL0WnRS5vmGNqcdyUBBpEbMDFOstSSxoaV&limit=5`).toPromise();
    }

    // ranking - player of the year
    public getRankingsPoy(isWinnings: boolean = false) {
        let reqUrl = `${environment.p5BackendHost}/rankings/poy/`;
        if (isWinnings) {
            reqUrl += '?sortby=totalWinnings';
        }
        return this.http.get<any>(reqUrl).toPromise();
    }

    // ranking - earnings
    public getRankingsEarnings() {
        return this.http.get<any>(`${environment.p5BackendHost}/rankings/earnings/`).toPromise();
    }

    // ranking - country
    public getRankingsCountry(countryCode: string) {
        return this.http.get<any>(`${environment.p5BackendHost}/rankings/earnings/${countryCode}`).toPromise();
    }

    // article
    public getArticleList(options: string) {
        return this.http.get<any>(`${environment.p5BackendHost}/articles/${options}`).toPromise();
    }
}
