import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VM } from '@models';

@Injectable({
    providedIn: 'root'
})
export class UpdateStakingService {
    public updateStaking = new BehaviorSubject<Array<VM.StakeUpdateResponseMessage>>([]);
    public constructor() {}
}
