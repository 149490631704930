import { bootstrapApplication } from '@angular/platform-browser';

import { enableProdMode } from '@angular/core';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';

import duration from 'dayjs/plugin/duration';
import { register } from 'swiper/element/bundle';
import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
import { appConfig } from '@app/app.config';
import { BehaviorSubject } from 'rxjs';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.extend(duration);

register();

declare let window: Window;

export class GlobalService {
    static articleItemList = new BehaviorSubject(this.getArticlesData());

    static getArticles = () => {
        this.setArticlesData(null);
        fetch(`${environment.p5BackendHost}/articles/?limit=5`)
            .then((response) => response.json())
            .then((data) => {
                this.setArticlesData(JSON.stringify(data));
            })
            .catch(() => {
                this.setArticlesData('fail');
            });
    };

    static setArticlesData(value) {
        this.articleItemList.next(value); // this will make sure to tell every subscriber about the change.
        window.localStorage.setItem('articles', value);
    }

    static getArticlesData() {
        return window.localStorage.getItem('articles');
    }
}

if (environment.production) {
    enableProdMode();
}

if ('/' === window.location.pathname) {
    GlobalService.getArticles();

    // let isOnMouseEnterEvent = false;
    // const deviceEventType = window.navigator.maxTouchPoints > 0 ? 'touchstart' : 'mousemove';
    // window.document.addEventListener(deviceEventType, (e) => {
    //     if (!isOnMouseEnterEvent) {
    //         bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
    //         isOnMouseEnterEvent = true;
    //     }
    // });
}
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
