import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { DTO, VM } from '@models';
import { Page } from '@helpers/page';
import { takeUntil } from 'rxjs/operators';
import { PlayerStateService } from '@core/states/player-state.service';
import { Subject } from 'rxjs';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { environment } from '@environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-account-menu',
    standalone: true,
    imports: [NgIf, RouterLink, RouterLinkActive],
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit, OnDestroy {
    @Input() public isInNavBar: boolean;
    @Input() public isOpen: boolean;
    @Output() public closeAccountMenu = new EventEmitter<boolean>();
    @Output() public closeSubMenu = new EventEmitter();

    public playerInfo: DTO.FrontOffice.Member.GetMyInfoResponse;
    public isSeller: boolean;
    private unsubscribe = new Subject<void>();

    public get isModeratorCPMenu() {
        const authorityList = [VM.Enums.Common.AccountGroup.Administrators, VM.Enums.Common.AccountGroup.Moderators, VM.Enums.Common.AccountGroup.P5sSuperMod, VM.Enums.Common.AccountGroup.ContentManager];
        return authorityList.includes(this.playerInfo?.p5Group?.id);
    }

    public get isWPAminMenu() {
        const authorityList = [VM.Enums.Common.AccountGroup.Administrators, VM.Enums.Common.AccountGroup.ContentManager];
        return authorityList.includes(this.playerInfo?.p5Group?.id);
    }

    public get isAdminCPMenu() {
        const authorityList = [VM.Enums.Common.AccountGroup.Administrators, VM.Enums.Common.AccountGroup.ContentManager];
        return authorityList.includes(this.playerInfo?.p5Group?.id);
    }

    public constructor(private router: Router, private playerStateService: PlayerStateService, private sanitizer: DomSanitizer) {}

    public ngOnInit() {
        this.playerInfo = this.playerStateService.info.getValue();

        this.playerStateService.info.pipe(takeUntil(this.unsubscribe)).subscribe((playerInfo) => {
            this.playerInfo = playerInfo;
            this.isSeller = playerInfo?.memberClass === DTO.Enums.Common.MemberClass.Seller;
        });
    }

    public goToP5Profile() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(Page.goToP5Profile(this.playerInfo));
    }

    public goToP5Page(path: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(Page.goToP5Page(path));
    }

    public goToAdminWP(path: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(Page.goToP5Admin(path));
    }

    public goToAdminCP() {
        return Page.goToP5Admin(environment.production ? 'kiNEYMeT' : 'admin');
    }

    public async logout() {
        await this.playerStateService.signOut();
        this.router.navigateByUrl('/');
    }

    public onCloseSideMenu() {
        this.closeAccountMenu.emit();
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
