import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SanitizerService {
    public constructor(private sanitizer: DomSanitizer) {}

    public sanitizeImageUrl(resource: string) {
        if (!resource) {
            return '';
        }

        const isCdnResource = resource.includes('//cdn.pocketfives.com') || resource.includes(environment.imageHost);
        return isCdnResource ? resource : this.sanitizer.bypassSecurityTrustResourceUrl(resource);
    }
}
